// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-distiller-en-tsx": () => import("./../../src/pages/distiller.en.tsx" /* webpackChunkName: "component---src-pages-distiller-en-tsx" */),
  "component---src-pages-distiller-tsx": () => import("./../../src/pages/distiller.tsx" /* webpackChunkName: "component---src-pages-distiller-tsx" */),
  "component---src-pages-distributors-tsx": () => import("./../../src/pages/distributors.tsx" /* webpackChunkName: "component---src-pages-distributors-tsx" */),
  "component---src-pages-index-en-tsx": () => import("./../../src/pages/index.en.tsx" /* webpackChunkName: "component---src-pages-index-en-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-philosophy-land-en-tsx": () => import("./../../src/pages/philosophy/land.en.tsx" /* webpackChunkName: "component---src-pages-philosophy-land-en-tsx" */),
  "component---src-pages-philosophy-land-tsx": () => import("./../../src/pages/philosophy/land.tsx" /* webpackChunkName: "component---src-pages-philosophy-land-tsx" */),
  "component---src-pages-philosophy-spirit-en-tsx": () => import("./../../src/pages/philosophy/spirit.en.tsx" /* webpackChunkName: "component---src-pages-philosophy-spirit-en-tsx" */),
  "component---src-pages-philosophy-spirit-tsx": () => import("./../../src/pages/philosophy/spirit.tsx" /* webpackChunkName: "component---src-pages-philosophy-spirit-tsx" */),
  "component---src-pages-products-shochu-en-tsx": () => import("./../../src/pages/products/shochu.en.tsx" /* webpackChunkName: "component---src-pages-products-shochu-en-tsx" */),
  "component---src-pages-products-shochu-tsx": () => import("./../../src/pages/products/shochu.tsx" /* webpackChunkName: "component---src-pages-products-shochu-tsx" */),
  "component---src-pages-products-spirits-en-tsx": () => import("./../../src/pages/products/spirits.en.tsx" /* webpackChunkName: "component---src-pages-products-spirits-en-tsx" */),
  "component---src-pages-products-spirits-tsx": () => import("./../../src/pages/products/spirits.tsx" /* webpackChunkName: "component---src-pages-products-spirits-tsx" */),
  "component---src-pages-products-whisky-en-tsx": () => import("./../../src/pages/products/whisky.en.tsx" /* webpackChunkName: "component---src-pages-products-whisky-en-tsx" */),
  "component---src-pages-products-whisky-tsx": () => import("./../../src/pages/products/whisky.tsx" /* webpackChunkName: "component---src-pages-products-whisky-tsx" */)
}

